import {
    call,
    put,
    select,
    delay,
} from 'redux-saga/effects';
import { replaceAuth } from '../Redux/AuthActions';
import { reloadApp } from '../Redux/ApplicationActions';
import { triggerChatSocket } from '../Redux/ChatListActions';
import storage from '../Utils/storage';

const AUTH_KEY = 'auth';

export function* loadAuthSaga() {
    // Check to see if auth is already in the redux store
    const auth = yield select((state) => state.auth);

    // don't do anything if already authenticated
    if (auth && auth.authenticated) {
        return;
    }

    const storedAuth = yield getFromStorage(AUTH_KEY);

    // replace the 'auth' slice in the redux store
    // with that loaded from storage
    if (storedAuth) {
        yield put(replaceAuth(storedAuth));
    }
}

export function* persistAuthSaga() {
    // Ensure auth reducer has updated state before we try to persist it
    yield delay(50);

    const auth = yield select((state) => state.auth);

    if (auth) {
        yield saveToStorageSaga(AUTH_KEY, auth);
    }
}

export function* refreshSuccessSaga() {
    yield put(triggerChatSocket());
}

export function* deleteAuthSaga() {
    yield call([storage, 'removeItem'], AUTH_KEY);

    // Reload the app (go to login screen) as we delete auth from
    // state when auth sessions are terminated and we listen for
    // actions like LOGOUT_SUCCESS, TOKEN_EXPIRED, and FORCE_RELOGIN
    yield put(reloadApp());
}

function* getFromStorage(key) {
    try {
        const data = yield call([storage, 'getItem'], key);
        return JSON.parse(data);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return null;
}

function* saveToStorageSaga(key, data) {
    try {
        yield call([storage, 'setItem'], key, JSON.stringify(data));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
}
