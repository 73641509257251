// @flow
import type {
    FormDefData, FormDefField, FormValueMap, Donor,
} from './types';
import { formatFullDateTimeWithTZ, isoNowUTC, formatDate as formatDateOnly } from './time';
import {
    UI_TYPE_SWITCH, UI_TYPE_SET_INPUT, UI_TYPE_CHECK, UI_TYPE_DATE, UI_TYPE_TIME, UI_TYPE_REFERRING,
} from '../Redux/Forms/FormTypes';

export const getFieldDef = (formDef: ?FormDefData, fieldId: number): ?FormDefField => {
    if (!formDef) return null;
    if (!formDef.sections || !formDef.sections.length || !formDef.sections.length === 0) return null;

    for (let i = 0; i < formDef.sections.length; i += 1) {
        const sectionDef = formDef.sections[i];
        // check each field and return first match
        for (let j = 0; j < sectionDef.fields.length; j += 1) {
            if (sectionDef.fields[j].id === fieldId) {
                return sectionDef.fields[j];
            }
        }
    }
    return null;
};

export const getFieldDisplay = (field: any): string => {
    let displayValue = '';
    if (field.value) {
        if ((field.uiType === UI_TYPE_TIME || field.isTime) && typeof field.value === 'string') {
            displayValue = formatFullDateTimeWithTZ(field.value);
        } else if ((field.uiType === UI_TYPE_DATE || field.isDate) && typeof field.value === 'string') {
            displayValue = formatDateOnly(field.value) || '';
        } else if (typeof field.value === 'string') {
            displayValue = field.value;
        } else if (field.uiType === UI_TYPE_CHECK) {
            displayValue = 'Complete';
        } else if (Array.isArray(field.value)) {
            displayValue = `\n${field.value.join('\n')}`;
        }
    }

    // NOTE: The one field type we are not handling is the 'switch' type (with a boolean value). This
    //     : method determines what the value display should look like in system messages inside of
    //     : chatrooms. All values in the task update system message follow the format of:
    //     : <field label>: <field value>
    //     : In the case of the 'switch', it will appear in the message if it has been 'checked' (true)
    //     : and in that case the <field label> is all that we want to display, no value so for those
    //     : cases, we do not check/assigned a displayValue

    return displayValue;
};

// Changes due to test:
//   remove redundant formId.
//   return basic field value if field definition not found.
//   repeated checks for fieldDef now redundant and removed, simplifying and making more readable
export const expandField = (formDef: FormDefData, fieldId: number, value: string | boolean): any => {
    const field = {};
    field.value = value;
    if (!formDef) return field;

    const fieldDef: ?FormDefField = getFieldDef(formDef, fieldId);
    if (!fieldDef) return field;

    if (fieldDef.fieldName) {
        field.name = fieldDef.fieldName;
    }

    let formatDate = field.value;
    if (fieldDef.uiType === UI_TYPE_SWITCH) {
        formatDate = isoNowUTC();
    }
    if (fieldDef.displayAs) {
        // Specific implementation for clinical event time - genericize as required.
        if (field.value) {
            if ((fieldDef.uiType === UI_TYPE_TIME || fieldDef.isTime) && typeof formatDate === 'string') {
                field.displayAs = `${fieldDef.displayAs || ''} ${formatFullDateTimeWithTZ(formatDate)}`;
            } else if ((fieldDef.uiType === UI_TYPE_DATE || fieldDef.isDate) && typeof formatDate === 'string') {
                field.displayAs = `${fieldDef.displayAs || ''} ${formatDateOnly(formatDate) || ''}`;
            } else if (typeof field.value === 'string') {
                field.displayAs = `${fieldDef.displayAs || ''} ${field.value}`;
            } else if (typeof field.value === 'boolean') {
                field.displayAs = fieldDef.displayAs || '';
            } else if (Array.isArray(field.value)) {
                field.displayAs = `${fieldDef.displayAs || ''} ${field.value.join(', ')}`;
            }
        } else {
            field.displayAs = '';
        }
    }
    if (fieldDef.emailDetails) {
        field.emailDetails = fieldDef.emailDetails;
    }
    // if (!fieldDef.displayAs && fieldDef.displayOnItem && fieldDef.uiType === UI_TYPE_SWITCH && value) {
    //     field.displayAs = fieldDef.title;
    // }

    return field;
};

export const isFieldDisabled = (item: any, sectionId: number, formDefData: FormDefData, formData: FormValueMap): boolean => {
    const behaviors = formDefData.disableBehavior;
    let fieldDisabled = false;
    if (behaviors && behaviors['DISABLE-NOT-N']) {
        const index = behaviors['DISABLE-NOT-N'];

        const disableNotN = formData[index] && formData[index].value ? formData[index].value : false;
        if (disableNotN) {
            if (!item.tags.includes('N')) {
                fieldDisabled = true;
            }
        }
    }

    // NOTE: Starting with Z so as not to conflict with
    //  any tags that already exist so we can potentially
    //  use both the enable and disable functionality on
    //  the same form.
    // TODO: Realy it would be nice to do this in a more generalized
    //  way.
    if (behaviors && behaviors['ENABLE-Z']) {
        const index = behaviors['ENABLE-Z'];
        const disableZ = formData[index] && !formData[index].value ? !formData[index].value : false;
        if (disableZ) {
            if (item.tags.includes('Z')) {
                fieldDisabled = true;
            }
        }
    }

    if (behaviors && behaviors['DISABLE-A']) {
        const index = behaviors['DISABLE-A'];

        const disableA = formData[index] && formData[index].value ? formData[index].value : false;
        if (disableA) {
            if (item.tags.includes('A')) {
                fieldDisabled = true;
            }
        }
    }

    if (behaviors && behaviors['DISABLE-B']) {
        const index = behaviors['DISABLE-B'];

        const disableB = formData[index] && formData[index].value ? formData[index].value : false;
        if (disableB) {
            if (item.tags.includes('B')) {
                fieldDisabled = true;
            }
        }
    }

    if (behaviors && behaviors['DISABLE-C']) {
        const index = behaviors['DISABLE-C'];

        const disableC = formData[index] && formData[index].value ? formData[index].value : false;
        if (disableC) {
            if (item.tags.includes('C')) {
                fieldDisabled = true;
            }
        }
    }

    if (behaviors && behaviors['DISABLE-NOT-N-SECTION-1']) {
        if (behaviors['DISABLE-NOT-N-SECTION-1'].sectionId === sectionId) {
            const index = behaviors['DISABLE-NOT-N-SECTION-1'].fieldId;

            const disableNotNSection1 = formData[index] && formData[index].value ? formData[index].value : false;
            if (disableNotNSection1) {
                if (!item.tags.includes('N')) {
                    fieldDisabled = true;
                }
            }
        }
    }

    if (behaviors && behaviors['DISABLE-NOT-N-SECTION-2']) {
        if (behaviors['DISABLE-NOT-N-SECTION-2'].sectionId === sectionId) {
            const index = behaviors['DISABLE-NOT-N-SECTION-2'].fieldId;

            const disableNotNSection2 = formData[index] && formData[index].value ? formData[index].value : false;
            if (disableNotNSection2) {
                if (!item.tags.includes('N')) {
                    fieldDisabled = true;
                }
            }
        }
    }
    return fieldDisabled;
};

export const isFieldHidden = (item: any, formDefData: FormDefData, formData: FormValueMap): boolean => {
    const behaviors = formDefData.disableBehavior;
    let hideField = false;

    if (behaviors && behaviors['REVEAL-B']) {
        const index = behaviors['REVEAL-B'];

        const revealB = formData[index] && formData[index].value ? formData[index].value === 'Positive' : false;
        if (revealB) {
            if (item.tags.includes('B')) {
                hideField = false;
            }
        } else {
            hideField = true;
        }
    }
    return hideField;
};

export const getItemsToDisplay = (donor: Donor | null, taskId: number, formDef: FormDefData | any): Array<string> => {
    const dataToDisplay = [];
    if (!taskId || !formDef || !formDef.sections) return dataToDisplay;

    const taskData = donor && donor.taskData && donor.taskData[taskId] ? donor.taskData[taskId] : null;
    const taskDataKeys = taskData ? Object.keys(taskData) : [];
    if (taskDataKeys.length <= 0) return dataToDisplay;

    const { sections, } = formDef;
    for (let i = 0; i < sections.length; i += 1) {
        const { fields, } = sections[i];
        for (let j = 0; j < fields.length; j += 1) {
            if (fields[j].displayOnItem) {
                if (taskDataKeys.includes(fields[j].id.toString())) {
                    // If there is data for this displayOnItem field, then try to display it.
                    if (taskData && taskData[fields[j].id]) {
                        if (taskData[fields[j].id].displayAs) {
                            // If the displayAs field has been set then display this, using the expanded displayAs attribute.
                            dataToDisplay.push(taskData[fields[j].id].displayAs);
                        } else if (taskData[fields[j].id].value && (taskData[fields[j].id].value !== false)) {
                            // This is still defined as a displayOnItem field
                            // so we should use the form field title and display - so long as there is some data present
                            if (fields[j].uiType === UI_TYPE_TIME || fields[j].isTime) {
                                dataToDisplay.push(`${fields[j].title}: ${formatFullDateTimeWithTZ(taskData[fields[j].id].value)}`);
                            } else if (fields[j].uiType === UI_TYPE_DATE || fields[j].isDate) {
                                dataToDisplay.push(`${fields[j].title}: ${formatDateOnly(taskData[fields[j].id].value) || ''}`);
                            } else if (fields[j].uiType === UI_TYPE_SET_INPUT) {
                                // Currently SET fields cannot be fully displayed on item as the mechanics haven't been elaborated,
                                // so just print the title as a prompter to dig deeper
                                dataToDisplay.push(fields[j].title);
                            } else if (taskData[fields[j].id].value.length > 20) {
                                dataToDisplay.push(`${fields[j].title}: ${taskData[fields[j].id].value.substring(0, 19)}...`);
                            } else if (fields[j].uiType === UI_TYPE_SWITCH || fields[j].uiType === UI_TYPE_CHECK) {
                                // for booleans display title if true
                                if (taskData[fields[j].id].value) {
                                    dataToDisplay.push(fields[j].title);
                                }
                            } else {
                                dataToDisplay.push(`${fields[j].title}: ${taskData[fields[j].id].value}`);
                            }
                        } else if (fields[j].uiType === UI_TYPE_REFERRING && donor && donor.taskData) {
                            const canDisplay = fields[j].referToTaskId
                                && fields[j].referToFieldId
                                && donor.taskData[fields[j].referToTaskId]
                                && donor.taskData[fields[j].referToTaskId][fields[j].referToFieldId];

                            if (canDisplay) {
                                const title = fields[j].displayAs ? fields[j].displayAs : fields[j].title;
                                const controllingTaskData = donor.taskData[fields[j].referToTaskId];
                                const controllingFieldData = controllingTaskData ? controllingTaskData[fields[j].referToFieldId] : undefined;
                                const value = controllingTaskData && controllingFieldData ? controllingFieldData.value : '';

                                if (value) {
                                    dataToDisplay.push(`${title}: ${value}`);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return dataToDisplay;
};
