// baseline offer template with everything (this should be the default if a person hasn't changed their preferences)
const baseTemplate = {
    donor: {
        ADMIT_CREATININE: true,
        ADMIT_DATE: true,
        AGE: true,
        BLOOD_TYPE: true,
        CAUSE_OF_DEATH: true,
        MECHANISM_OF_INJURY: true,
        CURRENT_CREATININE: true,
        OPO: true,
        ETHNICITY: true,
        HEIGHT: true,
        WEIGHT: true,
        BMI: true,
        ADMIT_COURSE: true,
        DOWNTIME_DURATION: true,
        CROSS_CLAMP_DATE: true,
        PUMP_NUMBER: true,
        WARM_ISCHEMIA_TIME: true,
        HEMODIALYSIS: true,
        CANCER: true,
        ETOH_USE: true,
        IV_DRUG_USE: true,
        SMOKING: true,
        PHS_INCREASED_RISK: true,
        VASOPRESSORS: true,
        BLOOD_PRODUCTS: true,
        MEDICATIONS: true,
        URINE_OUTPUT: true,
        ANURIC_TIME: true,
        ADMIT_BUN: true,
        PEAK_BUN: true,
        CURRENT_BUN: true,
        ADMIT_ALT: true,
        PEAK_ALT: true,
        CURRENT_ALT: true,
        ADMIT_AST: true,
        PEAK_AST: true,
        CURRENT_AST: true,
        ADMIT_BILIRUBIN: true,
        PEAK_BILIRUBIN: true,
        CURRENT_BILIRUBIN: true,
        ADMIT_ALK_PH: true,
        PEAK_ALK_PH: true,
        CURRENT_ALK_PH: true,
        ADMIT_INR: true,
        PEAK_INR: true,
        CURRENT_INR: true,
        ADMIT_AMYLASE: true,
        PEAK_AMYLASE: true,
        CURRENT_AMYLASE: true,
        ADMIT_LIPASE: true,
        PEAK_LIPASE: true,
        CURRENT_LIPASE: true,
        UA_PERFORMED: true,
        UA_PROTEIN_POSITIVE: true,
        SEROLOGIES: true,
        IMAGING_DETAILS: true,
        BIOPSY_DETAILS: true,
        OTHER_INFORMATION: true,
        GGT: true,
        SODIUM: true,
        CURRENT_LOCATION: true,
        DIABETES: true,
        HBA1C: true,
        HYPERTENSION: true,
        KDPI: true,
        MATCH_ID: true,
        ORGAN_TYPE: true,
        OR_DATE: true,
        PEAK_CREATININE: true,
        PRIMARY_HISTORY: true,
        RACE: true,
        SEX: true,
        TYPE_OF_DEATH: true,
        UNOS_ID: true,
    },
    recipient: {
        FULL_NAME: true,
        AGE: true,
        RACE: true,
        ETHNICITY: true,
        HEIGHT: true,
        WEIGHT: true,
        DIABETES_TYPE: true,
        BLOOD_TYPE: true,
        BMI: true,
        CPRA: true,
        CANCER: true,
        PVD: true,
        LAST_SEEN: true,
        ESRD_TIME: true,
        WAITLIST_NUMBER: true,
        MISMATCH_A: true,
        MISMATCH_B: true,
        MISMATCH_DR: true,
        MRN: true,
        PRIMARY_DIAGNOSIS: true,
        PRIORITY: true,
        PRIOR_TRANSPLANT: true,
        SEX: true,
    },
};

export const testTemplate = {
    donor: {
        ADMIT_CREATININE: true,
        ADMIT_DATE: true,
        AGE: true,
        BLOOD_TYPE: true,
        CAUSE_OF_DEATH: true,
        CURRENT_CREATININE: true,
        OPO: true,
        ETHNICITY: false,
        HEIGHT: true,
        WEIGHT: true,
        BMI: true,
        ADMIT_COURSE: true,
        DOWNTIME_DURATION: false,
        CROSS_CLAMP_DATE: true,
        PUMP_NUMBER: false,
        WARM_ISCHEMIA_TIME: true,
        HEMODIALYSIS: true,
        CANCER: false,
        ETOH_USE: true,
        IV_DRUG_USE: true,
        SMOKING: false,
        PHS_INCREASED_RISK: true,
        VASOPRESSORS: true,
        BLOOD_PRODUCTS: true,
        MEDICATIONS: true,
        URINE_OUTPUT: true,
        ANURIC_TIME: false,
        ADMIT_BUN: true,
        PEAK_BUN: true,
        CURRENT_BUN: true,
        ADMIT_ALT: true,
        PEAK_ALT: true,
        CURRENT_ALT: true,
        ADMIT_AST: true,
        PEAK_AST: true,
        CURRENT_AST: true,
        ADMIT_BILIRUBIN: true,
        PEAK_BILIRUBIN: true,
        CURRENT_BILIRUBIN: true,
        ADMIT_ALK_PH: true,
        PEAK_ALK_PH: true,
        CURRENT_ALK_PH: true,
        ADMIT_INR: true,
        PEAK_INR: true,
        CURRENT_INR: true,
        ADMIT_AMYLASE: true,
        PEAK_AMYLASE: true,
        CURRENT_AMYLASE: true,
        ADMIT_LIPASE: true,
        PEAK_LIPASE: true,
        CURRENT_LIPASE: true,
        UA_PERFORMED: true,
        UA_PROTEIN_POSITIVE: true,
        SEROLOGIES: true,
        IMAGING_DETAILS: false,
        BIOPSY_DETAILS: true,
        OTHER_INFORMATION: true,
        GGT: true,
        SODIUM: true,
        CURRENT_LOCATION: true,
        DIABETES: true,
        HBA1C: false,
        HYPERTENSION: true,
        KDPI: true,
        MATCH_ID: true,
        ORGAN_TYPE: true,
        OR_DATE: true,
        PEAK_CREATININE: true,
        PRIMARY_HISTORY: false,
        RACE: true,
        SEX: true,
        TYPE_OF_DEATH: true,
        UNOS_ID: true,
    },
    recipient: {
        FULL_NAME: true,
        AGE: true,
        RACE: true,
        ETHNICITY: true,
        HEIGHT: true,
        WEIGHT: true,
        DIABETES_TYPE: true,
        BLOOD_TYPE: true,
        BMI: true,
        CPRA: true,
        CANCER: true,
        PVD: true,
        LAST_SEEN: true,
        ESRD_TIME: true,
        WAITLIST_NUMBER: true,
        MISMATCH_A: true,
        MISMATCH_B: true,
        MISMATCH_DR: true,
        MRN: true,
        PRIMARY_DIAGNOSIS: true,
        PRIORITY: true,
        PRIOR_TRANSPLANT: true,
        SEX: true,
    },
};

export default baseTemplate;
