// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import FontIcon from 'react-toolbox/lib/font_icon';
import TextArea from 'react-textarea-autosize';
import EmojiMartPicker from 'emoji-mart-picker';
import ReactTooltip from 'react-tooltip';
import debounce from 'lodash.debounce';
import $ from 'jquery';
import { selectProfileEmail, selectProfileName } from 'txp-core';

import { logFirebaseEvent as _logFirebaseEvent } from '../Redux/ApplicationActions';
import {
    setChatInputHeight as _setChatInputHeight,
    setChatMediaHeight as _setChatMediaHeight,
} from '../Redux/ChatMessageActions';
import Mention from './Mention';
import ChatInputStyles from './Styles/ChatInputStyles';
import MentionStyles from './Styles/MentionStyles';
import isEmojiMessage from '../Utils/emojiRegex';
import type {
    AttachmentData,
    AvatarMap,
    ChatroomMemberMap,
    EventName,
} from '../Utils/types';
import { ImageMessageStrings, VideoMessageStrings, ApplicationMessageStrings } from '../Utils/types';
import ChatInputReply from './ChatInputReply';
import { replyAlertHeight, replyAlertVerticalPadding, replyAlertMarginTop } from './Styles/ChatMessageStyles';

const MAX_MESSAGE_LENGTH = 65535;
const CHAT_INPUT_MARGINS_TOTAL = 50; // sum of surrounding margin values around the TextArea
const BASE_CHAT_INPUT_HEIGHT = 66;
const REPLY_ALERT_HEIGHT = replyAlertHeight + replyAlertMarginTop + (replyAlertVerticalPadding * 2);

type Props = {
    chatId: number,
    showMention: boolean,
    members: ChatroomMemberMap,
    memberIds: Array<number>,
    membersTyping: Array<number>,
    avatars: AvatarMap,
    mentionedNames: Array<string>,
    filteredNames: Array<string>,
    message: string,
    media: Array<AttachmentData>,
    showMedia: number,
    selected: boolean,
    createChatroom: boolean,
    chatroomName: string,
    chatInputHeight: number,
    mediaHeight: number,
    replyHeight: number,
    deviceType: string,
    isConnected: boolean,
    isLeftSidebarOpen: boolean,
    isRightSidebarOpen: boolean,

    onSend: () => *,
    onTriggerAskAlan: () => *,
    onUploadMedia: () => *,
    onPasteMedia: (files: FileList) => *,
    setMessageMedia: (media: Array<AttachmentData>) => *,
    setMessage: (message: any) => *,
    setChatInputHeight: (chatId: number, height: number) => *,
    setChatMediaHeight: (chatId: number, height: number) => *,
    closeMention: () => *,
    addMentionedUser: (memberId: number, members: ChatroomMemberMap) => *,
    logFirebaseEvent: (name: EventName) => *,
};

type State = {
    onlyEmojis: boolean,
    inputText: string,
};

class ChatInput extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            onlyEmojis: false,
            inputText: '',
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentDidUpdate(prevProps: Props) {
        const {
            chatId,
            chatInputHeight,
            mediaHeight,
            message,
            media,
            setMessageMedia,
            setChatInputHeight,
            setChatMediaHeight,
        } = this.props;

        if ((prevProps.chatId !== chatId) && chatInputHeight === 0) {
            setChatInputHeight(chatId, BASE_CHAT_INPUT_HEIGHT);
        }

        if (message !== prevProps.message) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                inputText: message,
            });
        }

        // ensure that if media.file object is empty it is removed from the attachments array
        for (let i = 0; i < media.length; i += 1) {
            if (!media[i].file.name) {
                media.splice(i, 1);
                setMessageMedia(media);
            }
        }

        const mediaWrapperHeight = $('#mediaWrapper').outerHeight() || 0;
        if (mediaWrapperHeight !== mediaHeight) {
            setChatMediaHeight(chatId, mediaWrapperHeight);
        }

        const emojiMessage = isEmojiMessage(message) && message !== '';
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
            onlyEmojis: emojiMessage,
        });
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    onEmojiPickerOpen = () => {
        const {
            logFirebaseEvent,
        } = this.props;

        logFirebaseEvent('emoji-picker-click');
    };

    onUploadMediaClick = () => {
        const {
            onUploadMedia,
            logFirebaseEvent,
        } = this.props;

        logFirebaseEvent('upload-media-click');

        onUploadMedia();
    };

    onAskAlanClick = () => {
        const {
            onTriggerAskAlan,
            logFirebaseEvent,
        } = this.props;

        logFirebaseEvent('ask-alan-click');

        onTriggerAskAlan();
    };

    onSendClick = () => {
        const {
            onSend,
            logFirebaseEvent,
        } = this.props;

        logFirebaseEvent('send-message-click');

        onSend();
    };

    debounceChatTextInput = debounce((chatEvent) => this.props.setMessage(chatEvent), 100);

    autoCompleteMention = () => {
        const {
            members,
            memberIds,
            filteredNames,
            addMentionedUser,
        } = this.props;

        let memberId = -1;
        let i = 0;
        while (i < memberIds.length && memberId === -1) {
            if (members[memberIds[i].toString()].membershipStatus === 'Present') {
                if (filteredNames.includes(selectProfileName(members[memberIds[i].toString()].profile))) {
                    // chooses the first name in the list to autocomplete the mention
                    memberId = memberIds[i];
                }
            }
            i += 1;
        }

        // defensive code in case there is no member id found
        if (memberId !== -1) {
            addMentionedUser(memberId, members);
        }
    };

    handleKeyPress = (event: any) => {
        const {
            showMention,
            onSend,
        } = this.props;

        if ($('#chatInput').is(':focus')) {
            if (event.key === 'Enter' && event.shiftKey) {
                return;
            }
            if (event.key === 'Enter') {
                event.preventDefault();
                if (showMention) {
                    this.autoCompleteMention();
                } else {
                    onSend();
                }
            }
            if (event.keyCode === 9 && showMention) {
                event.preventDefault();
                this.autoCompleteMention();
            }
        }
    };

    escapeMention = (e: { which: number, }) => {
        const {
            closeMention,
        } = this.props;

        if (e.which === 27) {
            closeMention();
        }
    };

    removeMedia = (mediaItem: AttachmentData) => {
        const {
            media,
            setMessageMedia,
        } = this.props;

        const index = media.indexOf(mediaItem);
        media.splice(index, 1);
        setMessageMedia(media);
    };

    renderMedia(mediaItem: AttachmentData) {
        const isImage = (mediaItem && ImageMessageStrings.includes(mediaItem.mime));
        const isVideo = (mediaItem && VideoMessageStrings.includes(mediaItem.mime));
        const isApplication = (mediaItem && ApplicationMessageStrings.includes(mediaItem.mime));

        return (
            <div
                key={mediaItem.fileName}
                style={ChatInputStyles.uploadedFilesWrapper}
            >
                <ReactTooltip id="chatInput" />
                {(isImage) ? (
                    <img
                        style={ChatInputStyles.uploadedFile}
                        src={mediaItem.path}
                        alt="Uploaded File"
                        data-for="chatInput"
                        data-tip={mediaItem.fileName}
                        data-delay-show="100"
                        data-effect="solid"
                    />
                ) : null}
                {(isVideo) ? (
                    <video // eslint-disable-line
                        style={ChatInputStyles.uploadedFile}
                        src={mediaItem.path}
                        data-for="chatInput"
                        data-tip={mediaItem.fileName}
                        data-delay-show="100"
                        data-effect="solid"
                    />
                ) : null}
                {(isApplication) ? (
                    <div style={ChatInputStyles.uploadedApplicationWrapper}>
                        <iframe
                            style={ChatInputStyles.uploadedApplicationMedia}
                            src={mediaItem.path}
                            title="chat_input_application_iframe"
                            data-for="chatInput"
                            data-tip={mediaItem.fileName}
                            data-delay-show="100"
                            data-effect="solid"
                        />
                    </div>
                ) : null}

                <button
                    type="button"
                    style={ChatInputStyles.deleteFileButton}
                    value={mediaItem}
                    onClick={() => this.removeMedia(mediaItem)}
                    className="closeButton"
                >
                    <FontIcon style={ChatInputStyles.deleteFileImg} value="close" alt="Remove File" />
                </button>
            </div>
        );
    }

    render() {
        const {
            chatId,
            showMention,
            members,
            memberIds,
            membersTyping,
            avatars,
            mentionedNames,
            filteredNames,
            message,
            media,
            showMedia,
            selected,
            createChatroom,
            chatroomName,
            mediaHeight,
            chatInputHeight,
            replyHeight,
            deviceType,
            isConnected,
            isLeftSidebarOpen,
            isRightSidebarOpen,
            setMessage,
            closeMention,
            addMentionedUser,
            setChatInputHeight,
            onPasteMedia,
        } = this.props;

        const {
            onlyEmojis,
            inputText,
        } = this.state;

        let buttonDisabled;
        if (createChatroom) {
            buttonDisabled = (chatroomName === '') || !isConnected;
        } else {
            buttonDisabled = message
                ? (message.length <= 0 || !selected || (message.match(/^\s*$/) !== null) || !isConnected)
                : media ? (media.length <= 0 || !media || !selected || !isConnected) : true;
        }

        let inputDisabled = true;
        if (selected) {
            inputDisabled = false;
        } else if (createChatroom) {
            inputDisabled = false;
        }

        const showMentionedNames = mentionedNames && mentionedNames.length > 0;
        const mentionsBottom = chatInputHeight + mediaHeight + replyHeight;

        let isTyping = '';

        if (membersTyping && members) {
            if (membersTyping.length > 2) {
                isTyping = 'Multiple people are typing...';
            } else if (membersTyping.length === 1) {
                const memberId = membersTyping[0].toString();
                if (members[memberId]) {
                    isTyping = `${selectProfileName(members[memberId].profile, selectProfileEmail(members[memberId].profile))
                    } is typing...`;
                }
            } else if (membersTyping.length === 2) {
                const firstMemberId = membersTyping[0].toString();
                const secondMemberId = membersTyping[1].toString();
                if (members[firstMemberId] && members[secondMemberId]) {
                    isTyping = `${selectProfileName(members[firstMemberId].profile, selectProfileEmail(members[firstMemberId].profile))
                    } and ${
                        selectProfileName(members[secondMemberId].profile, selectProfileEmail(members[secondMemberId].profile))
                    } are typing...`;
                } else if (members[firstMemberId]) {
                    isTyping = `${selectProfileName(members[firstMemberId].profile, selectProfileEmail(members[firstMemberId].profile))
                    } is typing...`;
                } else if (members[secondMemberId]) {
                    isTyping = `${selectProfileName(members[secondMemberId].profile, selectProfileEmail(members[secondMemberId].profile))
                    } is typing...`;
                }
            }
        }

        return (
            <div
                className={`mentionsWrapper${
                    isLeftSidebarOpen ? ' wrapperLeftOpen' : ''
                }${isRightSidebarOpen ? ' wrapperRightOpen' : ''
                }${(isLeftSidebarOpen && isRightSidebarOpen) ? ' wrapperBothOpen' : ''}`}
                style={{ bottom: mentionsBottom, }}
            >
                {showMention
                    ? (
                        <Mention
                            members={members}
                            memberIds={memberIds}
                            avatars={avatars}
                            filteredNames={filteredNames}
                            createChatroom={createChatroom}
                            closeMention={closeMention}
                            addMentionedUser={addMentionedUser}
                        />
                    ) : <div />}
                {showMentionedNames && !inputDisabled
                    ? (
                        <div style={MentionStyles.mentionedNamesContainer}>
                            <span style={MentionStyles.mentionedNamesLabel}>Users mentioned in this message:</span>
                            {
                                mentionedNames.map((name: string) => (
                                    <div key={name}>
                                        <span style={MentionStyles.mentionedNames}>{name}</span>
                                    </div>
                                ))
                            }
                        </div>
                    ) : <div />}
                <div className={`wrapper${
                    isLeftSidebarOpen ? ' wrapperLeftOpen' : ''
                }${isRightSidebarOpen ? ' wrapperRightOpen' : ''
                }${(isLeftSidebarOpen && isRightSidebarOpen) ? ' wrapperBothOpen' : ''}`}
                >
                    <div className="border" />
                    {
                        (media && media.length > 0) && showMedia > 0 && !inputDisabled
                            ? (
                                <div id="mediaWrapper">
                                    {
                                        media.map((mediaItem: AttachmentData) => this.renderMedia(mediaItem))
                                    }
                                </div>
                            ) : null
                    }
                    <ChatInputReply chatId={chatId} />
                    <TextArea
                        className={onlyEmojis ? 'messageInputEmoji' : 'messageInput'}
                        id="chatInput"
                        placeholder={inputDisabled ? 'Select a room to send a message...'
                            : 'Type your message... Press Enter to send or Shift-Enter for a new line.'}
                        minRows={1}
                        maxRows={6}
                        maxLength={MAX_MESSAGE_LENGTH}
                        disabled={inputDisabled}
                        onHeightChange={(height) => setChatInputHeight(chatId, height + CHAT_INPUT_MARGINS_TOTAL)}
                        onChange={(changeEvent) => {
                            this.debounceChatTextInput(changeEvent);

                            this.setState({
                                inputText: changeEvent.target.value,
                            });
                        }}
                        onKeyDown={this.escapeMention}
                        onPaste={(data) => {
                            if (data.clipboardData && data.clipboardData.files) {
                                onPasteMedia(data.clipboardData.files);
                                data.clipboardData.clearData();
                            }
                        }}
                        value={inputText}
                    />
                    <div style={ChatInputStyles.isTypingContainer}>
                        {selected
                            ? (
                                <span style={ChatInputStyles.isTypingText}>
                                    {isTyping}
                                </span>
                            ) : <div />}
                    </div>
                    <div className="buttonWrapper">
                        <ReactTooltip id="chatInput" />
                        {deviceType === 'laptop'
                            ? (
                                <div>
                                    <EmojiMartPicker
                                        style={ChatInputStyles.emojiPicker}
                                        color="#0069FF"
                                        title="Pick your emoji..."
                                        emoji="female-doctor"
                                        placement="bottomRight"
                                        onOpen={this.onEmojiPickerOpen}
                                        onChange={(emoji) => setMessage(emoji)}
                                    >
                                        <button
                                            className={inputDisabled ? 'emojiPickerButtonDisabled' : 'emojiPickerButton'}
                                            type="button"
                                            disabled={inputDisabled}
                                            data-for="chatInput"
                                            data-tip="Add an emoji"
                                            data-delay-show="250"
                                            data-effect="solid"
                                        >
                                            <span>
                                                <FontIcon
                                                    style={
                                                        inputDisabled
                                                            ? ChatInputStyles.fontIconColorDisabled : ChatInputStyles.fontIconColor
                                                    }
                                                    value="mood"
                                                    alt="Choose an emoji"
                                                />
                                            </span>
                                        </button>
                                    </EmojiMartPicker>
                                    <div className="attachFileButton">
                                        <button
                                            style={
                                                inputDisabled
                                                    ? ChatInputStyles.chatInputFileButtonDisabled : ChatInputStyles.chatInputFileButton
                                            }
                                            type="button"
                                            disabled={inputDisabled}
                                            data-for="chatInput"
                                            data-tip="Upload a file"
                                            data-delay-show="250"
                                            data-effect="solid"
                                            onClick={this.onUploadMediaClick}
                                        >
                                            <span>
                                                <FontIcon
                                                    style={
                                                        inputDisabled
                                                            ? ChatInputStyles.fontIconColorDisabled : ChatInputStyles.fontIconColor
                                                    }
                                                    value="attach_file"
                                                    alt="Attach File"
                                                />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="attachFileButton">
                                    <button
                                        style={
                                            inputDisabled
                                                ? ChatInputStyles.chatInputFileButtonDisabled : ChatInputStyles.chatInputFileButton
                                        }
                                        type="button"
                                        disabled={inputDisabled}
                                        data-for="chatInput"
                                        data-tip="Upload a file"
                                        data-delay-show="250"
                                        data-effect="solid"
                                        onClick={this.onUploadMediaClick}
                                    >
                                        <span>
                                            <FontIcon
                                                style={
                                                    inputDisabled
                                                        ? ChatInputStyles.fontIconColorDisabled : ChatInputStyles.fontIconColor
                                                }
                                                value="attach_file"
                                                alt="Attach File"
                                            />
                                        </span>
                                    </button>
                                </div>
                            )}
                        <button
                            className={(inputDisabled || createChatroom) ? 'askAlanButtonDisabled' : 'askAlanButton'}
                            type="button"
                            disabled={inputDisabled || createChatroom}
                            data-for="chatInput"
                            data-tip="Launch Snapshot"
                            data-delay-show="250"
                            data-effect="solid"
                            onClick={this.onAskAlanClick}
                        >
                            <span>
                                <FontIcon
                                    style={
                                        (inputDisabled || createChatroom)
                                            ? ChatInputStyles.fontIconColorDisabled : ChatInputStyles.fontIconColor
                                    }
                                    value="bar_chart"
                                    alt="Snapshot"
                                />
                            </span>
                        </button>
                        <div className="sendButtonWrapper">
                            <Button
                                style={buttonDisabled ? ChatInputStyles.chatInputButtonDisabled : ChatInputStyles.chatInputButton}
                                label={createChatroom ? 'Create' : 'Send'}
                                ripple={false}
                                disabled={buttonDisabled}
                                data-for="chatInput"
                                data-tip={createChatroom ? 'Create room' : 'Send message'}
                                data-delay-show="250"
                                data-effect="solid"
                                onClick={this.onSendClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const chatId = state.chatList.activeChatId || 0;
    const chatMessage = chatId ? state.chatMessage[chatId] : null;
    const isReplyMessage = chatMessage && chatMessage.replyMessageId;

    return {
        chatId,
        avatars: state.chatList.avatars || {},
        chatInputHeight: state.chatMessage[chatId] && state.chatMessage[chatId].chatInputHeight
            ? state.chatMessage[chatId].chatInputHeight : 0,
        mediaHeight: state.chatMessage[chatId] && state.chatMessage[chatId].mediaHeight
            ? state.chatMessage[chatId].mediaHeight : 0,
        replyHeight: isReplyMessage ? REPLY_ALERT_HEIGHT : 0,
    };
};

export default connect(mapStateToProps, {
    setChatInputHeight: _setChatInputHeight,
    setChatMediaHeight: _setChatMediaHeight,
    logFirebaseEvent: _logFirebaseEvent,
})(ChatInput);
