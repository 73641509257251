// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';

import ChatMessageStyles from './Styles/ChatMessageStyles';
import { formatDateTime } from '../Utils/time';
import type {
    Organization,
    UserProfile,
    ChatroomInfo,
    ChatroomMessage,
} from '../Utils/types';
import { MessageTypes } from '../Utils/types';

import baseTemplate from '../Utils/offerTemplate';
import { keys, values } from '../Utils/Object';

type Props = {
    offerContent: any,
    user: UserProfile | Organization,
    isMine: boolean,
    width: ?number,
    currentChat: ChatroomInfo,
};

class OfferData extends PureComponent<Props> {
    // Decide whether or not to display this item based on whether it is in the template and whether it has a value
    getDisplayStyle = (id: string, template: any, value: string | number): any => {
        if (id in template.donor && value !== '' && value !== 0 && value !== null && value !== undefined) {
            return {};
        }
        return { display: 'none', };
    };

    // Conditionally render sections
    shouldRender = (template: any, currentValues: any): boolean => {
        let falseCount = 0;

        const valuesKeys = keys(currentValues);
        for (let i = 0; i < valuesKeys.length; i += 1) {
            if (
                currentValues[valuesKeys[i]] === ''
                || currentValues[valuesKeys[i]] === 0
                || currentValues[valuesKeys[i]] === null
                || currentValues[valuesKeys[i]] === undefined
                || !(valuesKeys[i] in template.donor)
            ) {
                falseCount += 1;
            }
        }

        return falseCount !== Object.keys(currentValues).length;
    };

    render() {
        const {
            identification,
            demographics,
            admission,
            transplant,
            medications,
            labs,
            observations,
            history,
            misc,
            // eslint-disable-next-line camelcase
            opo_donor_id,
        } = this.props.offerContent;

        const {
            user,
            isMine,
            width,
            currentChat,
        } = this.props;

        let offerHasBeenSuperseded = false;

        if (currentChat && currentChat.messages) {
            values(currentChat.messages).forEach((message: ChatroomMessage) => {
                if (message.messageType === MessageTypes.askAlanVoid) {
                    offerHasBeenSuperseded = true;
                }
            });
        }

        // CR type never actually changes but this is effectively a Templated Case (for rendering purposes) once Ask Alan has been used
        const chatType = (offerHasBeenSuperseded) ? 'Templated Case' : currentChat.chatroomType;
        let offerHeader;
        let unosIdLabel = 'Donor ID: ';
        if (chatType === 'Templated Case' && !offerHasBeenSuperseded) {
            offerHeader = 'New Templated Case';
        } else if (chatType === 'Case' && !offerHasBeenSuperseded) {
            offerHeader = 'New Case';
        } else if (chatType === 'Donor') {
            unosIdLabel = 'UNOS Donor ID: ';
            if (!offerHasBeenSuperseded) {
                offerHeader = 'New Case';
            }
        } else if (chatType === 'Standard' && !offerHasBeenSuperseded) {
            offerHeader = 'New Snapshot';
        } else {
            offerHeader = 'Snapshot';
        }

        const templatePreferencesExist = user.templatePreferences && keys(user.templatePreferences).length > 0;

        // Apply user's template preferences if they exist, otherwise use the base template (all fields)
        const usedTemplate = templatePreferencesExist ? user.templatePreferences : baseTemplate;

        // eslint-disable-next-line max-len
        const baseTemplateMessage = 'This snapshot displays all possible fields. If data was not provided for a certain field then that field was omitted.';
        // eslint-disable-next-line max-len
        const userTemplateMessage = 'This snapshot displays only fields from the organization\'s template. If data was not provided for a certain field then that field was omitted.';

        const disclaimer = templatePreferencesExist ? userTemplateMessage : baseTemplateMessage;

        return (
            <div>
                {chatType === 'Case' || chatType === 'Donor'
                    ? (
                        <div style={{ width: width ? width / 1.5 : 'auto', }}>
                            <div style={ChatMessageStyles.offerTitle}>
                                <span style={ChatMessageStyles.offerTitleText}>{offerHeader}</span>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div style={ChatMessageStyles.offerTitle}>
                                <span style={ChatMessageStyles.offerTitleText}>{offerHeader}</span>
                            </div>
                            <div style={ChatMessageStyles.offerTitle}>
                                <span style={ChatMessageStyles.offerDisclaimer}>{disclaimer}</span>
                            </div>
                            <div style={ChatMessageStyles.offerSubtitle}>
                                <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                <span style={ChatMessageStyles.offerSubtitleText}>Identification</span>
                                <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                            </div>
                        </div>
                    )}
                <Row style={ChatMessageStyles.offerIdHeader}>
                    {chatType === 'Donor'
                        ? (
                            <Col xs={4}>
                                <span style={ChatMessageStyles.offerInfo}><b>OPO Donor ID: </b></span>
                                {/* eslint-disable-next-line camelcase */}
                                <span style={ChatMessageStyles.offerInfo}>{opo_donor_id}</span>
                            </Col>
                        ) : <span />}
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>{unosIdLabel}</b></span>
                        <span style={ChatMessageStyles.offerInfo}>{identification.unos_id}</span>
                    </Col>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Organ Type: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{identification.organ_type}</span>
                    </Col>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>Match ID: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{identification.match_id}</span>
                    </Col>
                </Row>
                <Row style={this.getDisplayStyle('OPO', usedTemplate, identification.opo)}>
                    <Col xs={4}>
                        <span style={ChatMessageStyles.offerInfo}><b>OPO: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{identification.opo}</span>
                    </Col>
                    <Col
                        xs={7}
                        style={this.getDisplayStyle('CURRENT_LOCATION', usedTemplate, identification.current_location)}
                    >
                        <span style={ChatMessageStyles.offerInfo}><b>Current Location: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{identification.current_location}</span>
                    </Col>
                </Row>
                {
                    this.shouldRender(usedTemplate,
                        {
                            AGE: demographics.age,
                            SEX: demographics.sex,
                            BLOOD_TYPE: demographics.blood_type,
                            BMI: demographics.bmi,
                            WEIGHT: demographics.weight,
                            HEIGHT: demographics.height,
                            RACE: demographics.race,
                            ETHNICITY: demographics.ethnicity,
                        })
                        ? (
                            <div>
                                <div style={ChatMessageStyles.offerSubtitle}>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                    <span style={ChatMessageStyles.offerSubtitleText}>Demographics</span>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                </div>
                                <Row>
                                    <Col xs={4} style={this.getDisplayStyle('AGE', usedTemplate, demographics.age)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Age: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{demographics.age}</span>
                                        <span style={ChatMessageStyles.offerInfo}> years</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('SEX', usedTemplate, demographics.sex)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Sex: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{demographics.sex}</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('BLOOD_TYPE', usedTemplate, demographics.blood_type)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Blood Type: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{demographics.blood_type}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4} style={this.getDisplayStyle('BMI', usedTemplate, demographics.bmi)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>BMI: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{demographics.bmi}</span>
                                        <span style={ChatMessageStyles.offerInfo}> kg/m²</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('WEIGHT', usedTemplate, demographics.weight)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Weight: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{demographics.weight}</span>
                                        <span style={ChatMessageStyles.offerInfo}> kg</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('HEIGHT', usedTemplate, demographics.height)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Height: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{demographics.height}</span>
                                        <span style={ChatMessageStyles.offerInfo}> cm</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4} style={this.getDisplayStyle('RACE', usedTemplate, demographics.race)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Race: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{demographics.race}</span>
                                    </Col>
                                    <Col xs={7} style={this.getDisplayStyle('ETHNICITY', usedTemplate, demographics.ethnicity)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Ethnicity: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{demographics.ethnicity}</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            ADMIT_DATE: admission.admit_date,
                            ADMIT_COURSE: admission.admit_course,
                            CAUSE_OF_DEATH: admission.cause_of_death,
                            MECHANISM_OF_INJURY: admission.mechanism_of_injury,
                            DOWNTIME_DURATION: admission.downtime_duration,
                            TYPE_OF_DEATH: admission.type_of_death,
                        })
                        ? (
                            <div>
                                <div style={ChatMessageStyles.offerSubtitle}>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                    <span style={ChatMessageStyles.offerSubtitleText}>Admission</span>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                </div>
                                <Row>
                                    <Col xs={11} style={this.getDisplayStyle('ADMIT_DATE', usedTemplate, admission.admit_date)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Admit Date: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{formatDateTime(admission.admit_date)}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={11} style={this.getDisplayStyle('ADMIT_COURSE', usedTemplate, admission.admit_course)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Admit Course: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{admission.admit_course}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4} style={this.getDisplayStyle('CAUSE_OF_DEATH', usedTemplate, admission.cause_of_death)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Cause of Death: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{admission.cause_of_death}</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('MECHANISM_OF_INJURY', usedTemplate, admission.mechanism_of_injury)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Mechanism of Injury: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{admission.mechanism_of_injury}</span>
                                    </Col>
                                    {/* eslint-disable-next-line max-len */}
                                    <Col xs={4} style={this.getDisplayStyle('DOWNTIME_DURATION', usedTemplate, admission.downtime_duration)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Downtime: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{admission.downtime_duration}</span>
                                        <span style={ChatMessageStyles.offerInfo}> minutes</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('TYPE_OF_DEATH', usedTemplate, admission.type_of_death)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Type of Death: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{admission.type_of_death}</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            OR_DATE: transplant.or_date,
                            CROSS_CLAMP_DATE: transplant.cross_clamp_date,
                            WARM_ISCHEMIA_TIME: transplant.warm_ischemia_time,
                            KDPI: transplant.kdpi,
                            PUMP_NUMBER: transplant.pump_number,
                            HEMODIALYSIS: transplant.hemodialysis,
                        })
                        ? (
                            <div>
                                <div style={ChatMessageStyles.offerSubtitle}>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                    <span style={ChatMessageStyles.offerSubtitleText}>Transplant</span>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                </div>
                                <Row style={this.getDisplayStyle('OR_DATE', usedTemplate, transplant.or_date)}>
                                    <Col xs={12}>
                                        <span style={ChatMessageStyles.offerInfo}><b>OR Date: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{formatDateTime(transplant.or_date)}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    {/* eslint-disable-next-line max-len */}
                                    <Col xs={12} style={this.getDisplayStyle('CROSS_CLAMP_DATE', usedTemplate, transplant.cross_clamp_date)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Cross Clamp: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{formatDateTime(transplant.cross_clamp_date)}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        xs={4}
                                        style={this.getDisplayStyle('WARM_ISCHEMIA_TIME', usedTemplate, transplant.warm_ischemia_time)}
                                    >
                                        <span style={ChatMessageStyles.offerInfo}><b>WIT: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{transplant.warm_ischemia_time}</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('KDPI', usedTemplate, transplant.kdpi)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>KDPI: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{transplant.kdpi}</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('PUMP_NUMBER', usedTemplate, transplant.pump_number)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Pumps: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{transplant.pump_number}</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('HEMODIALYSIS', usedTemplate, transplant.hemodialysis)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>On Hemodialysis: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{transplant.hemodialysis ? 'Yes' : 'No'}</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            PRIMARY_HISTORY: history.primaryHistory,
                            PHS_INCREASED_RISK: history.phs_increased_risk,
                            ETOH_USE: history.etoh_use,
                            IV_DRUG_USE: history.iv_drug_use,
                            CANCER: history.cancer,
                            HYPERTENSION: history.hypertension,
                            DIABETES: history.diabetes,
                            SMOKING: history.smoking,
                        })
                        ? (
                            <div>
                                <div style={ChatMessageStyles.offerSubtitle}>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                    <span style={ChatMessageStyles.offerSubtitleText}>History</span>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                </div>
                                <Row>
                                    <Col xs={12} style={this.getDisplayStyle('PRIMARY_HISTORY', usedTemplate, history.primary_history)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Primary History: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{history.primary_history}</span>
                                    </Col>
                                    {/* eslint-disable-next-line max-len */}
                                    <Col xs={4} style={this.getDisplayStyle('PHS_INCREASED_RISK', usedTemplate, history.phs_increased_risk)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>PHS Inc. Risk: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{history.phs_increased_risk}</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('ETOH_USE', usedTemplate, history.etoh_use)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>ETOH Use: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{history.etoh_use ? 'Yes' : 'No'}</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('IV_DRUG_USE', usedTemplate, history.iv_drug_use)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>IV Drug User: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{history.iv_drug_use ? 'Yes' : 'No'}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4} style={this.getDisplayStyle('CANCER', usedTemplate, history.cancer)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Hist. Cancer: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{history.cancer ? 'Yes' : 'No'}</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('HYPERTENSION', usedTemplate, history.hypertension)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Hist. Hypertension: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{history.hypertension ? 'Yes' : 'No'}</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('DIABETES', usedTemplate, history.diabetes)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Hist. Diabetes: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{history.diabetes ? 'Yes' : 'No'}</span>
                                    </Col>
                                </Row>
                                <Row style={this.getDisplayStyle('SMOKING', usedTemplate, history.smoking)}>
                                    <Col xs={4}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Hist. Smoking: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{history.smoking}</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            VASOPRESSORS: medications.vasopressors,
                            BLOOD_PRODUCTS: medications.blood_products,
                            MEDICATIONS: medications.medications,
                        })
                        ? (
                            <div>
                                <div style={ChatMessageStyles.offerSubtitle}>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                    <span style={ChatMessageStyles.offerSubtitleText}>Medications</span>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                </div>
                                <Row>
                                    <Col xs={12} style={this.getDisplayStyle('VASOPRESSORS', usedTemplate, medications.vasopressors)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Pressor Support: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{medications.vasopressors}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} style={this.getDisplayStyle('BLOOD_PRODUCTS', usedTemplate, medications.blood_products)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Blood Products: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{medications.blood_products}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} style={this.getDisplayStyle('MEDICATIONS', usedTemplate, medications.medications)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Other Medications: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{medications.medications}</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            ADMIT_CREATININE: labs.admit_creatinine,
                            CURRENT_CREATININE: labs.peak_creatinine,
                            PEAK_CREATININE: labs.current_creatinine,
                            ADMIT_BUN: labs.admit_bun,
                            PEAK_BUN: labs.peak_bun,
                            CURRENT_BUN: labs.current_bun,
                            UA_PERFORMED: labs.ua_performed,
                            UA_PROTEIN_POSITIVE: labs.ua_protein_positive,
                            ADMIT_AST: labs.admit_ast,
                            PEAK_AST: labs.peak_ast,
                            CURRENT_AST: labs.current_ast,
                            ADMIT_ALT: labs.admit_alt,
                            PEAK_ALT: labs.peak_alt,
                            CURRENT_ALT: labs.current_alt,
                            ADMIT_BILIRUBIN: labs.admit_bilirubin,
                            PEAK_BILIRUBIN: labs.peak_bilirubin,
                            CURRENT_BILIRUBIN: labs.current_bilirubin,
                            ADMIT_ALK_PH: labs.admit_alk_ph,
                            PEAK_ALK_PH: labs.peak_alk_ph,
                            CURRENT_ALK_PH: labs.current_alk_ph,
                            ADMIT_INR: labs.admit_inr,
                            PEAK_INR: labs.peak_inr,
                            CURRENT_INR: labs.current_inr,
                            GGT: labs.ggt,
                            SODIUM: labs.sodium,
                            SEROLOGIES: labs.serologies,
                            HBA1C: labs.hba1c,
                        })
                        ? (
                            <div style={ChatMessageStyles.offerSubtitle}>
                                <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                <span style={ChatMessageStyles.offerSubtitleText}>Labs</span>
                                <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            HBA1C: labs.hba1c,
                        })
                        ? (
                            <div>
                                <Row>
                                    <Col xs={4} style={this.getDisplayStyle('HBA1C', usedTemplate, labs.hba1c)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>HbA1c: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.hba1c}</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            ADMIT_CREATININE: labs.admit_creatinine,
                            PEAK_CREATININE: labs.peak_creatinine,
                            CURRENT_CREATININE: labs.current_creatinine,
                        })
                        ? (
                            <div>
                                <Col xs={12}>
                                    <div style={ChatMessageStyles.center}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Creatinine: </b></span>
                                    </div>
                                </Col>
                                <Row style={ChatMessageStyles.textCenter}>
                                    <Col xs={4} style={this.getDisplayStyle('ADMIT_CREATININE', usedTemplate, labs.admit_creatinine)}>
                                        <span style={ChatMessageStyles.offerInfo}>Admit: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.admit_creatinine}</span>
                                        <span style={ChatMessageStyles.offerInfo}> mg/dL</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('PEAK_CREATININE', usedTemplate, labs.peak_creatinine)}>
                                        <span style={ChatMessageStyles.offerInfo}>Peak: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.peak_creatinine}</span>
                                        <span style={ChatMessageStyles.offerInfo}> mg/dL</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('CURRENT_CREATININE', usedTemplate, labs.current_creatinine)}>
                                        <span style={ChatMessageStyles.offerInfo}>Current: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.current_creatinine}</span>
                                        <span style={ChatMessageStyles.offerInfo}> mg/dL</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            ADMIT_BUN: labs.admit_bun,
                            PEAK_BUN: labs.peak_bun,
                            CURRENT_BUN: labs.current_bun,
                        })
                        ? (
                            <div>
                                <Col xs={12}>
                                    <div style={ChatMessageStyles.center}>
                                        <span style={ChatMessageStyles.offerInfo}><b>BUN: </b></span>
                                    </div>
                                </Col>
                                <Row style={ChatMessageStyles.textCenter}>
                                    <Col xs={4} style={this.getDisplayStyle('ADMIT_BUN', usedTemplate, labs.admit_bun)}>
                                        <span style={ChatMessageStyles.offerInfo}>Admit: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.admit_bun}</span>
                                        <span style={ChatMessageStyles.offerInfo}> mg/dL</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('PEAK_BUN', usedTemplate, labs.peak_bun)}>
                                        <span style={ChatMessageStyles.offerInfo}>Peak: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.peak_bun}</span>
                                        <span style={ChatMessageStyles.offerInfo}> mg/dL</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('CURRENT_BUN', usedTemplate, labs.current_bun)}>
                                        <span style={ChatMessageStyles.offerInfo}>Current: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.current_bun}</span>
                                        <span style={ChatMessageStyles.offerInfo}> mg/dL</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            UA_PERFORMED: labs.ua_performed,
                            UA_PROTEIN_POSITIVE: labs.ua_protein_positive,
                        })
                        ? (
                            <div>
                                <Col xs={12}>
                                    <div style={ChatMessageStyles.center}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Urinalysis: </b></span>
                                    </div>
                                </Col>
                                <Row style={ChatMessageStyles.textCenter}>
                                    <Col xs={4} style={this.getDisplayStyle('UA_PERFORMED', usedTemplate, labs.ua_performed)}>
                                        <span style={ChatMessageStyles.offerInfo}>Performed: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.ua_performed}</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('UA_PROTEIN_POSITIVE', usedTemplate, labs.ua_protein_positive)}>
                                        <span style={ChatMessageStyles.offerInfo}>Positive: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.ua_protein_positive}</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            ADMIT_AST: labs.admit_ast,
                            PEAK_AST: labs.peak_ast,
                            CURRENT_AST: labs.current_ast,
                        })
                        ? (
                            <div>
                                <Col xs={12}>
                                    <div style={ChatMessageStyles.center}>
                                        <span style={ChatMessageStyles.offerInfo}><b>AST: </b></span>
                                    </div>
                                </Col>
                                <Row style={ChatMessageStyles.textCenter}>
                                    <Col xs={4} style={this.getDisplayStyle('ADMIT_AST', usedTemplate, labs.admit_ast)}>
                                        <span style={ChatMessageStyles.offerInfo}>Admit: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.admit_ast}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('PEAK_AST', usedTemplate, labs.peak_ast)}>
                                        <span style={ChatMessageStyles.offerInfo}>Peak: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.peak_ast}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('CURRENT_AST', usedTemplate, labs.current_ast)}>
                                        <span style={ChatMessageStyles.offerInfo}>Current: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.current_ast}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            ADMIT_ALT: labs.admit_alt,
                            PEAK_ALT: labs.peak_alt,
                            CURRENT_ALT: labs.current_alt,
                        })
                        ? (
                            <div>
                                <Col xs={12}>
                                    <div style={ChatMessageStyles.center}>
                                        <span style={ChatMessageStyles.offerInfo}><b>ALT: </b></span>
                                    </div>
                                </Col>
                                <Row style={ChatMessageStyles.textCenter}>
                                    <Col xs={4} style={this.getDisplayStyle('ADMIT_ALT', usedTemplate, labs.admit_alt)}>
                                        <span style={ChatMessageStyles.offerInfo}>Admit: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.admit_alt}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('PEAK_ALT', usedTemplate, labs.peak_alt)}>
                                        <span style={ChatMessageStyles.offerInfo}>Peak: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.peak_alt}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('CURRENT_ALT', usedTemplate, labs.current_alt)}>
                                        <span style={ChatMessageStyles.offerInfo}>Current: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.current_alt}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            ADMIT_BILIRUBIN: labs.admit_bilirubin,
                            PEAK_BILIRUBIN: labs.peak_bilirubin,
                            CURRENT_BILIRUBIN: labs.current_bilirubin,
                        })
                        ? (
                            <div>
                                <Col xs={12}>
                                    <div style={ChatMessageStyles.center}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Bilirubin: </b></span>
                                    </div>
                                </Col>
                                <Row style={ChatMessageStyles.textCenter}>
                                    <Col xs={4} style={this.getDisplayStyle('ADMIT_BILIRUBIN', usedTemplate, labs.admit_bilirubin)}>
                                        <span style={ChatMessageStyles.offerInfo}>Admit: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.admit_bilirubin}</span>
                                        <span style={ChatMessageStyles.offerInfo}> mg/dL</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('PEAK_BILIRUBIN', usedTemplate, labs.peak_bilirubin)}>
                                        <span style={ChatMessageStyles.offerInfo}>Peak: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.peak_bilirubin}</span>
                                        <span style={ChatMessageStyles.offerInfo}> mg/dL</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('CURRENT_BILIRUBIN', usedTemplate, labs.current_bilirubin)}>
                                        <span style={ChatMessageStyles.offerInfo}>Current: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.current_bilirubin}</span>
                                        <span style={ChatMessageStyles.offerInfo}> mg/dL</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            ADMIT_ALK_PH: labs.admit_alk_ph,
                            PEAK_ALK_PH: labs.peak_alk_ph,
                            CURRENT_ALK_PH: labs.current_alk_ph,
                        })
                        ? (
                            <div>
                                <Col xs={12}>
                                    <div style={ChatMessageStyles.center}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Alkaline Phosphatase: </b></span>
                                    </div>
                                </Col>
                                <Row style={ChatMessageStyles.textCenter}>
                                    <Col xs={4} style={this.getDisplayStyle('ADMIT_ALK_PH', usedTemplate, labs.admit_alk_ph)}>
                                        <span style={ChatMessageStyles.offerInfo}>Admit: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.admit_alk_ph}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('PEAK_ALK_PH', usedTemplate, labs.peak_alk_ph)}>
                                        <span style={ChatMessageStyles.offerInfo}>Peak: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.peak_alk_ph}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('CURRENT_ALK_PH', usedTemplate, labs.current_alk_ph)}>
                                        <span style={ChatMessageStyles.offerInfo}>Current: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.current_alk_ph}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            ADMIT_INR: labs.admit_inr,
                            PEAK_INR: labs.peak_inr,
                            CURRENT_INR: labs.current_inr,
                        })
                        ? (
                            <div>
                                <Col xs={12}>
                                    <div style={ChatMessageStyles.center}>
                                        <span style={ChatMessageStyles.offerInfo}><b>INR: </b></span>
                                    </div>
                                </Col>
                                <Row style={ChatMessageStyles.textCenter}>
                                    <Col xs={4} style={this.getDisplayStyle('ADMIT_INR', usedTemplate, labs.admit_inr)}>
                                        <span style={ChatMessageStyles.offerInfo}>Admit: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.admit_inr}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('PEAK_INR', usedTemplate, labs.peak_inr)}>
                                        <span style={ChatMessageStyles.offerInfo}>Peak: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.peak_inr}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('CURRENT_INR', usedTemplate, labs.current_inr)}>
                                        <span style={ChatMessageStyles.offerInfo}>Current: </span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.current_inr}</span>
                                        <span style={ChatMessageStyles.offerInfo}> IU/L</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                <Row style={ChatMessageStyles.textCenter}>
                    <Col xs={4} style={this.getDisplayStyle('Gamma-glutamyl transpeptidase', usedTemplate, labs.ggt)}>
                        <span style={ChatMessageStyles.offerInfo}><b>GGT: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{labs.ggt}</span>
                        <span style={ChatMessageStyles.offerInfo}> U/L</span>
                    </Col>
                    <Col xs={4} style={this.getDisplayStyle('SODIUM', usedTemplate, labs.sodium)}>
                        <span style={ChatMessageStyles.offerInfo}><b>Sodium: </b></span>
                        <span style={ChatMessageStyles.offerInfo}>{labs.sodium}</span>
                        <span style={ChatMessageStyles.offerInfo}> mEq/L</span>
                    </Col>
                </Row>
                {
                    this.shouldRender(usedTemplate,
                        {
                            SEROLOGIES: labs.serologies,
                        })
                        ? (
                            <div>
                                <Row style={{ ...ChatMessageStyles.center, ...ChatMessageStyles.clear, }}>
                                    <span style={ChatMessageStyles.offerInfo}><b>Serologies: </b></span>
                                </Row>
                                <Row>
                                    <Col xs={4}>
                                        <span style={ChatMessageStyles.offerInfo}><b>CMV: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.serologies.CMV ? 'Yes' : 'No'}</span>
                                    </Col>
                                    <Col xs={4}>
                                        <span style={ChatMessageStyles.offerInfo}><b>EBV: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.serologies.EBV ? 'Yes' : 'No'}</span>
                                    </Col>
                                    <Col xs={4}>
                                        <span style={ChatMessageStyles.offerInfo}><b>HIV: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.serologies.HIV ? 'Yes' : 'No'}</span>
                                    </Col>
                                    <Col xs={4}>
                                        <span style={ChatMessageStyles.offerInfo}><b>HIV-Nat: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.serologies.HIVNat ? 'Yes' : 'No'}</span>
                                    </Col>
                                    <Col xs={4}>
                                        <span style={ChatMessageStyles.offerInfo}><b>HBV: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.serologies.HBV ? 'Yes' : 'No'}</span>
                                    </Col>
                                    <Col xs={4}>
                                        <span style={ChatMessageStyles.offerInfo}><b>HBV-Nat: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.serologies.HBVNat ? 'Yes' : 'No'}</span>
                                    </Col>
                                    <Col xs={4}>
                                        <span style={ChatMessageStyles.offerInfo}><b>HCV: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.serologies.HCV ? 'Yes' : 'No'}</span>
                                    </Col>
                                    <Col>
                                        <span style={ChatMessageStyles.offerInfo}><b>HCV-Nat: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.serologies.HCVNat ? 'Yes' : 'No'}</span>
                                    </Col>
                                    <Col xs={11}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Other: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{labs.serologies.other}</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            URINE_OUTPUT: observations.urine_output,
                            ANURIC_TIME: observations.anuric_time,
                        })
                        ? (
                            <div>
                                <div style={ChatMessageStyles.offerSubtitle}>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                    <span style={ChatMessageStyles.offerSubtitleText}>Observations</span>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                </div>
                                <Row>
                                    <Col xs={4} style={this.getDisplayStyle('URINE_OUTPUT', usedTemplate, observations.urine_output)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Urine Output: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{observations.urine_output}</span>
                                        <span style={ChatMessageStyles.offerInfo}> ml/hr</span>
                                    </Col>
                                    <Col xs={4} style={this.getDisplayStyle('ANURIC_TIME', usedTemplate, observations.anuric_time)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Anuric Time: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{observations.anuric_time}</span>
                                        <span style={ChatMessageStyles.offerInfo}> hours</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
                {
                    this.shouldRender(usedTemplate,
                        {
                            IMAGING_DETAILS: misc.imaging_details,
                            BIOPSY_DETAILS: misc.biopsy_details,
                            OTHER_INFORMATION: misc.other_information,
                        })
                        ? (
                            <div>
                                <div style={ChatMessageStyles.offerSubtitle}>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                    <span style={ChatMessageStyles.offerSubtitleText}>Other</span>
                                    <span style={isMine ? ChatMessageStyles.offerSubtitleLineSelf : ChatMessageStyles.offerSubtitleLine} />
                                </div>
                                <Row>
                                    <Col xs={12} style={this.getDisplayStyle('IMAGING_DETAILS', usedTemplate, misc.imaging_details)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Imaging Details: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{misc.imaging_details}</span>
                                    </Col>
                                </Row>
                                <Row style={this.getDisplayStyle('BIOPSY_DETAILS', usedTemplate, misc.biopsy_details)}>
                                    <Col xs={12}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Biopsy Details: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{misc.biopsy_details}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} style={this.getDisplayStyle('OTHER_INFORMATION', usedTemplate, misc.other_information)}>
                                        <span style={ChatMessageStyles.offerInfo}><b>Other: </b></span>
                                        <span style={ChatMessageStyles.offerInfo}>{misc.other_information}</span>
                                    </Col>
                                </Row>
                            </div>
                        ) : <div />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentChat: state.chatList.activeChatId && state.chatList.chats ? state.chatList.chats[`${state.chatList.activeChatId}`] : {},
});

export default connect(mapStateToProps, {
})(OfferData);
